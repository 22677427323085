<template>
    <div id="mapContainer" class="map-card" :style="`width:${width};height:${height};`"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {
    name: "MapPoint",
    props: {
        value: {
            type: Object,
            default: () => ({lat: -46, lon: -71})
        },
        width: {type: String, default: () => '200px'},
        height: {type: String, default: () => '200px'}
    },
    data() {
        return {
            accessToken: process.env.VUE_APP_MAPGL_TOKEN,
            map: null,
            marker_list: [],
            moving_dot_index: 0,
            marker: null,
            route: null,
            w_req: null,
        };
    },
    created() {
    },
    destroyed() {
    },
    mounted() {
        mapboxgl.accessToken = this.accessToken;
        this.map = new mapboxgl.Map({
            container: "mapContainer",
            style: "mapbox://styles/mapbox/streets-v11",
            center: [this.value.lon, this.value.lat],
            zoom: 6

        });

        // https://github.com/mapbox/mapbox-gl-js/issues/2268#issuecomment-401979967
        this.map.on('load',   () => {
            const waiting = () => {
                if (!this.map.isStyleLoaded()) {
                    setTimeout(waiting, 200);
                } else {
                    new mapboxgl.Marker()
                    .setLngLat([this.value.lon, this.value.lat])
                    .addTo(this.map)
                }
            };
            waiting();
        });
        
        
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
</style>