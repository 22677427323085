<template>
    <div>
		<Toast />
		<Toolbar>
			<template #left>
				<Dropdown class="tb-dropdown p-d-flex p-ai-center p-pl-2" v-model="selectedTemplate" :options="templates" optionLabel="ticket.name" :placeholder="$t('Select a Template')" @change="setForm()" />
				<Button  icon="pi pi-plus" @click="showNewDialog = true" />
			</template>
			<template #right>
				<div v-if="selectedTemplate">
					<Button  :label="$t('Save')" @click="saveTemplate()" />
					<Button class="p-button-danger p-ml-2" :label="$t('Close')" @click="selectedTemplate = null" />
				</div>
			</template>
		</Toolbar>
		<TabView v-if="selectedTemplate" class="p-mt-2">
			<TabPanel :header="$t('Preview')">
				<div v-if="Object.keys(sortedForm).length" class="p-fluid p-grid p-formgrid">
					<AutoFormField v-for="(i, k) in sortedForm" v-model="sortedForm[k]" :key="k" />
				</div>
				<div v-else class="p-fluid p-grid p-formgrid">
					{{$t("There are no fields in this form yet.")}}
				</div>
			</TabPanel>
			<TabPanel :header="$t('Editor')">
				<div class="p-d-flex p-mb-2">
					<Dropdown class="tb-dropdown p-d-flex p-ai-center p-pl-2 " v-model="selectedField" :options="formFields" optionLabel="name" optionValue="model" :placeholder="$t('Add a field')" />
					<Button  icon="pi pi-plus" @click="addField()" :disabled="!selectedField"/>
				</div>
				<vueJsonEditor v-if="selectedTemplate" v-model="sortedForm" :expandedOnStart="true" :key="editorKey" />
			</TabPanel>
		</TabView>
        <Dialog :header="$t('Create New Template')" :visible.sync="showNewDialog" :dismissableMask="false" :style="{width: '600px'}" :modal="true" :closable="true">
            {{$t("Enter the desired template name. The name should reflect it purpose and language.")}}
            <div class="p-field p-mt-2">
                <div class="p-inputgroup">
                    <InputText type="text" v-model="newModelName" />
                    <Button class="p-ml-2 p-button-info" :label="$t('Create')" @click="createTemplate()" :disabled="!newModelName" />
                </div>            
            </div>
        </Dialog>
	</div>
</template>

<script>
import AutoFormField from "../components/AutoFormField.vue";
import ApiService from "../service/ApiService";

import vueJsonEditor from "vue-json-editor";

export default {
	name: "FormEditor",
	data() {
		return {
			editorKey: 1,
            api: null,
			templates: [],
			loading: true,
			selectedTemplate: null,
			sortedForm: {},
			showNewDialog: false,
			newModelName: '',
			selectedField: null,
			formFields : [
				{
					name: 'Calendar',
					model : {
						type: 'calendar',
						placeholder : "",
						disabled: false,
						value: "",
						label: "Calendar",
						mandatory: true,
						invalidMessage : "",
						help: ""
					},
				},
				{
					name: 'Captcha',
					model : {
						type: 'captcha'
					},
				},
				{
					name: 'Checkbox',
					model : {
						type: 'checkbox',
						disabled: false,
						value: true,
						label: "Checkbox",
						mandatory: true,
						invalidMessage : "",
						help: ""
					},
				},
				{
					name: 'Chip',
					model : {
						type: 'chip',
						disabled: false,
						value: "",
						label: "Chip",
						mandatory: true,
						invalidMessage : "",
						help: ""
					},
				},
				{
					name: 'Dropdown',
					model : {
						type: 'dropdown',
						placeholder : "",
						disabled: false,
						value: "",
						label: "Dropdown",
						mandatory: true,
						invalidMessage : "",
						options: [
							'example1',
							'example2'
						],
						help: ""
					},
				},
				{
					name: 'Input',
					model : {
						type: 'text',
						placeholder : "",
						disabled: false,
						value: "",
						label: "Text Field",
						mandatory: true,
						invalidMessage : "",
						help: ""
					},
				},
				{
					name: 'Instructions',
					model : {
						type: 'instructions',
						value: "Instruction text",
					},
				},
				{
					name: 'Multiselect',
					model : {
						type: 'multiselect',
						placeholder : "",
						disabled: false,
						value: "",
						label: "Multiselect",
						mandatory: true,
						invalidMessage : "",
						options: [
							'example1',
							'example2'
						],
						help: ""
					},
				},
				{
					name: 'Text Area',
					model : {
						type: 'textarea',
						placeholder : "",
						disabled: false,
						value: "",
						label: "Text Area",
						mandatory: true,
						invalidMessage : "",
						help: ""
					},
				},
				{
					name: 'Title',
					model : {
						type: 'title',
						value: "Title text",
					},
				},
				{
					name: 'Rating',
					model : {
						type: 'rating',
						disabled: false,
						stars: 10,
						value: 5,
						label: "Rating",
						mandatory: true,
						invalidMessage : "",
						help: ""
					},
				},
			]
		};
	},
    created() {
        this.api = new ApiService();
		this.refreshTemplates();
    },
	methods: {
		addField() {
			let index = ((parseInt(Object.keys(this.sortedForm)
			.sort((a,b) => {
				if(a < b) { return 1; }
				if(a > b) { return -1; }
				return 0;
			})[0]?.substring(0,4) || 0) + 10) + '').padStart(4, '0');
			this.sortedForm[index + this.selectedField.type] = this.selectedField;
			this.editorKey += 1;
		},
		refreshTemplates() {
			this.api.getFromEp('tickets', {filters:JSON.stringify([{field: 'type', value: 'template'}])}).then((templates) => {
				if (templates.length) {
					this.templates = templates;
				}
				this.loading = false;
			})    			
		},
		saveTemplate() {
			this.selectedTemplate.ticket.form = this.sortedForm;
            this.api.postToEp('tickets', this.selectedTemplate).then(() => {
				this.refreshTemplates();
                this.$toast.add({severity:'success', summary: this.$t('Success'), detail:this.$t('Ticket updated successfully'), life: 3000});
            })
		},
		setForm() {
			let tf =  {};
			if (this.selectedTemplate){
				Object.keys(this.selectedTemplate.ticket.form)
				.sort((a,b) => {
					if(a < b) { return -1; }
					if(a > b) { return 1; }
					return 0;
				})
				.forEach((k) => tf[k] = this.selectedTemplate.ticket.form[k]);
			}
			this.sortedForm = tf, null, 2;
		},
		createTemplate() {
			this.selectedTemplate = {
				ticket: {
					form: {},
					name: this.newModelName,
					template: "platform_access_request",
					subtype: "default",
					type: 'template'
				}
			}
            this.api.postToEp('tickets', this.selectedTemplate).then(() => {
				this.refreshTemplates();
				this.selectedTemplate = null;
				this.showNewDialog = false;
                this.$toast.add({severity:'success', summary: this.$t('Success'), detail:this.$t('Ticket updated successfully'), life: 3000});
            })
		}
	},
	computed: {

	},
	components: {
		AutoFormField,
		vueJsonEditor
	},
};
</script>

<style scoped>
.tb-dropdown {
	height: 33px;
	width: 250px;
}

/deep/ div.jsoneditor-menu {
    background-color: #003c71;
    border-bottom: 1px solid #ced4da;
	color: #495057;
}

/deep/ div.jsoneditor {
    border: 1px solid #ced4da;
}

/deep/ table.jsoneditor-search div.jsoneditor-frame {
    border: 1px solid #ced4da;
}

</style>
