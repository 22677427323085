<template>
	<div :class="`p-field p-col-12 p-mt-3 ${!['textarea', 'instructions', 'title', 'mappoint'].includes(model.type) ? 'p-md-6' : ''}`" >
        <div v-if="model.type === 'checkbox'">
            <div class="p-field-checkbox">
                <Checkbox id="binary" v-model="model.value" :binary="true"  :disabled="model.disabled || forceDisabled" />
                <label :class="invalidClass()" for="binary">{{model.label}}</label>
            </div>
            <small v-if="!isValid" class="p-invalid p-ml-2">{{$t(model.invalidMessage || 'This field is mandatory')}}</small>
            <small v-else>{{$t(model.help)}}</small>
        </div>
        <div v-else-if="model.type === 'rating'" style="margin-top: -1.2rem">
            <label :class="invalidClass()" for="field">{{$t(model.label)}}</label>
            <Rating   v-model="model.value" :stars="model.stars || 5"  :disabled="model.disabled || forceDisabled" />
            <small v-if="!isValid" class="p-invalid">{{$t(model.invalidMessage || 'This field is mandatory')}}</small>
            <small v-else>{{$t(model.help)}}</small>
        </div>
        <div v-else-if="model.type === 'instructions'">
            <span>{{model.value}}</span>
        </div>
        <div v-else-if="model.type === 'title'">
            <h1>{{model.value}}</h1>
        </div>
        <div v-else-if="model.type === 'textarea'">
            <label :class="invalidClass()" for="field">{{$t(model.label)}}</label>
            <Textarea  v-model="model.value" :autoResize="model.autoResize" :rows="model.rows || 5" :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <small v-if="!isValid" class="p-invalid">{{$t(model.invalidMessage || 'This field is mandatory')}}</small>
            <small v-else>{{$t(model.help)}}</small>
        </div>
        <div v-else-if="model.type === 'mappoint'">
            <MapPoint v-model="model.value" :width="`${getWidth - 80}px`" height="200px"/>
        </div>
        <div v-else-if="model.type === 'captcha' && !forceDisabled">
            <vue-recaptcha sitekey="6Leo3fcbAAAAAPjY3x8lx0ETtfP48F9ec_AEx-mL" @verify="(v) => model.value = v?true:false" @error="model.value = false" @expired="model.value = false"/>
        </div>
        <span v-else :class="`${(model.mandatory || model.help) ? 'p-float-label-off' : ''} p-float-label`">
            <InputText v-if="model.type === 'text'"  type="text" v-model="model.value" :placeholder="model.placeholder" :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <Calendar v-if="model.type === 'calendar'" :showIcon="true" :style="`width: ${model.width || '10rem'}`"  v-model="model.value" :monthNavigator="model.monthNavigator" :yearNavigator="model.yearNavigator" :showButtonBar="model.showButtonBar" :showTime="model.showTime" :yearRange="model.yearRange" :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <Dropdown v-else-if="model.type === 'dropdown'" style="height: 29px"  v-model="model.value" :options="model.options" :optionLabel="model.optionLabel" :placeholder="model.placeholder" :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <MultiSelect v-else-if="model.type === 'multiselect'"  v-model="model.value" :options="model.options" :optionLabel="model.optionLabel" :placeholder="model.placeholder" display="chip" :filter="model.filter" :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <Chips v-else-if="model.type === 'chips'"  v-model="model.value" :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <InputText v-else-if="model.type === 'password'"  type="password" v-model="model.value" :placeholder="model.placeholder" :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <Password v-else-if="model.type === 'p-password'"  v-model="model.value" toggleMask :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <InputNumber v-else-if="model.type === 'number'"  v-model="model.value" :mode="model.mode" :useGrouping="model.useGrouping" :minFractionDigits="model.minFractionDigits" :maxFractionDigits="model.maxFractionDigits" :class="invalidClass()" :disabled="model.disabled || forceDisabled" />
            <label :class="invalidClass()" for="field" >{{$t(model.label)}}</label>
            <small v-if="!isValid" class="p-invalid">{{$t(model.invalidMessage || 'This field is mandatory')}}</small>
            <small v-else>{{$t(model.help)}}</small>
        </span>
	</div>
</template>

<script>
import MapPoint from "../components/MapPoint.vue";
import VueRecaptcha from 'vue-recaptcha';

export default {
	props: {
        value: Object,
        forceDisabled: {
            type: Boolean,
            default: false
        }
	},
	data() {
		return {
		};
	},
    created(){
        if (this.value.type === 'captcha') {
            this.value.value = false;
            this.$emit('input', this.value);
        }
    },
	updated() {
		this.$emit('check-valid');
	},
    computed: {
        model: {
            get(){
                return this.value;
            },
            set(v){
                this.$emit('input', v);
            }
        },
        isValid() {
            if (this.model.validate) {
                return this.model.validate(this.model.value);
            }
            else if (this.model.mandatory) {
                if (['text', 'p-password', 'password', 'calendar', 'chips', 'rating', 'dropdown', 'textarea', 'checkbox'].includes(this.model.type)) {
                    if (!this.model.value) {
                        return false;
                    }
                }
                else if (['number'].includes(this.model.type)) {
                    if (!this.model.value && this.model.value !== 0) {
                        return false;
                    }
                }
                else if (['multiselect'].includes(this.model.type)) {
                    if (!Array.isArray(this.model.value) || !this.model.value.length) {
                        return false;
                    }
                }
            }
            return true;
        },
        getWidth() {
            return window.innerWidth * 0.8;
        }
    },
	methods: {
        invalidClass() {
            return this.isValid ? '':'p-invalid';
        }
	},
	components: {
        MapPoint,
        VueRecaptcha
	},
};
</script>

<style lang="scss" scoped>
.p-float-label input:focus ~ label, .p-float-label input.p-filled ~ label, .p-float-label textarea:focus ~ label, .p-float-label textarea.p-filled ~ label, .p-float-label .p-inputwrapper-focus ~ label, .p-float-label .p-inputwrapper-filled ~ label {
    left: 0px;
    font-size: 14px;
    color: #333F48;
}
.p-float-label-off input:focus ~ label, .p-float-label-off input.p-filled ~ label, .p-float-label-off textarea:focus ~ label, .p-float-label-off textarea.p-filled ~ label, .p-float-label-off .p-inputwrapper-focus ~ label, .p-float-label-off .p-inputwrapper-filled ~ label {
    top: -.75rem;
    left: 0px;
    font-size: 14px;
    color: #333F48;
}
.p-float-label-off label {
    top: 30%;
    font-size: 12px;
}

.p-float-label label {
    font-size: 12px;
}

.p-invalid {
    color: #FF0000
}
</style>
